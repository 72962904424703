import PropTypes from 'prop-types'
import React from 'react'

const CollectionCard = ({
  image,
  image_alt,
  isLoading,
  name,
  price,
  recyclingProgramTag,
  url,
}) => {
  const responsiveImgPlaceholder = image
    ? 'responsiveImageEmpty'
    : 'responsiveImage_img'

  return (
    <div className="collectionCard_item col-6 col-sm-4 col-md-3 col-xxl-2">
      <div className="collectionCard">
        <div className="collectionCard_wrapper collectionCardWrapper">
          <a
            className={
              isLoading
                ? 'collectionCard_imgLink blurred'
                : 'collectionCard_imgLink'
            }
            href={url}
            tabIndex="-1"
          />
          <div className="collectionCard_topLabel"></div>
          <div
            className={`collectionCard_media ${responsiveImgPlaceholder}`}
            style={image ? null : { paddingBottom: '82%' }}
          >
            <img
              alt={image_alt}
              className="collectionCard_img lazyload"
              data-sizes="auto"
              src={image}
            />
          </div>
          <div className="collectionCard_collect">
            {recyclingProgramTag && recyclingProgramTag}
          </div>
          <div className="collectionCard_content">
            <div className="collectionCard_textWrapper">
              <h4 className="collectionCard_title">{name}</h4>
              <div className="collectionCard_label">
                <span className="price-label"> {price}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionCard

CollectionCard.propTypes = {
  image: PropTypes.string,
  image_alt: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string,
  price: PropTypes.string,
  recyclingProgramTag: PropTypes.string,
  url: PropTypes.string,
}
