import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import Loader from '../Loader'
import ActiveFilters from './ActiveFilters'
import CollectionCard from './CollectionCard'
import CollectionFilter from './CollectionFilter'
import CollectionFilterCheckBox from './CollectionFilterCheckBox'
import LoadMore from './LoadMore'
import { deleteQueryParams, getQueryParams, setQueryParams } from './QueryParamsActions'
import Search from './Search'

const ListingPage = ({ isBrigades, t, brigade_ids = [] }) => {
  const [productsError, setProductsError] = useState(null)
  const [filtersError, setFiltersError] = useState(null)
  const [products, setProducts] = useState(null)
  const [filters, setFilters] = useState(null)
  const [availabilityValue, setAvailabilityValue] = useState([])
  const [priceValue, setPriceValue] = useState('')
  const [priceTitle, setPriceTitle] = useState('')
  const [availabilityTitle, setAvailabilityTitle] = useState('')
  const [categoryValue, setCategoryValue] = useState([])
  const [categoryTitle, setCategoryTitle] = useState([])
  const [keyword, setKeyword] = useState('')
  const [isAvailabilityParamsRemoved, setIsAvailabilityParamsRemoved] = useState(false)
  const [isCategoryParamsRemoved, setIsCategoryParamsRemoved] = useState(false)
  const [isPriceParamsRemoved, setIsPriceParamsRemoved] = useState(false)
  const [isKeywordParamsRemoved, setIsKeywordParamsRemoved] = useState(false)
  const pageHiddenQueryParams = isBrigades ? 'exclude_categories[]=contests&exclude_categories[]=products' : 'exclude_categories[]=contests'
  const [loadingStates, setLoadingStates] = useState({
    filters: false,
    moreProducts: false,
    products: false
  })

  const isLoading = () => loadingStates.filters || loadingStates.products || loadingStates.moreProducts

  let activeFilters = [
    { title: availabilityTitle, type: 'availabilities[]' },
    { title: [...new Set(categoryTitle)], type: 'categories[]', value: [...new Set(categoryValue)]} ,
    { title: priceTitle, type: 'price' },
    { title: keyword, type: 'keyword' },
  ]

  const getFilters = () => {
    setLoadingStates(prevState => ({ ...prevState, filters: true }))
    fetch('/api/v3/internal/common/filters')
      .then((data) => data.json())
      .then((res) => setFilters(res))
      .catch((error) => setFiltersError(error))
      .finally(() => {
        setLoadingStates(prevState => ({ ...prevState, filters: false }))
      })
  }

  const getProducts = (availability, category, price, key) => {
    setLoadingStates(prevState => ({ ...prevState, products: true }))
    const categoryQP = category?.map((item) => {
      return `categories[]=${item}&`
    })

    let url = `/api/v3/internal/common/listing?${categoryQP.join('')}availabilities[]=${availability || availabilityValue}&price=${
      price || priceValue
    }&query=${key || keyword}&${pageHiddenQueryParams}`

    brigade_ids.forEach((brigade_id) => {
      url += `&brigade_ids[]=${brigade_id}`
    })

    fetch(url.replace(',', ''))
      .then((data) => data.json())
      .then((res) => {
        setProducts(res)
      })
      .then(() => {
        setIsKeywordParamsRemoved(false)
      })
      .catch((er) => setProductsError(er))
      .finally(() => {
        setLoadingStates(prevState => ({ ...prevState, products: false }))
      })
  }

  const loadMoreProducts = (page) => {
    setLoadingStates(prevState => ({ ...prevState, moreProducts: true }))
    const categoryQP = categoryValue?.map((item) => {
      return `categories[]=${item}&`
    })
    fetch(
      `/api/v3/internal/common/listing?${categoryQP.join('')}&availabilities[]=${availabilityValue}&price=${priceValue}&query=${keyword}&page=${page}&${pageHiddenQueryParams}`
    )
      .then((data) => data.json())
      .then((res) => {
        setProducts((prevState) => {
          return {
            pagination: res.pagination,
            records: [...prevState.records, ...res.records],
          }
        })
      })
      .catch((er) => setProductsError(er))
      .finally(() => {
        setLoadingStates(prevState => ({ ...prevState, moreProducts: false }))
      })
  }

  useEffect(() => {
    deleteQueryParams(isKeywordParamsRemoved, isAvailabilityParamsRemoved, isCategoryParamsRemoved, isPriceParamsRemoved)
  }, [isAvailabilityParamsRemoved, isCategoryParamsRemoved, isKeywordParamsRemoved, isPriceParamsRemoved])

  useEffect(() => {
    getFilters()
  }, [])

  useEffect(() => {
    setQueryParams(availabilityValue, priceValue, keyword, categoryValue)

    const params = getQueryParams()

    params?.availability && setAvailabilityValue(params.availability)
    params?.category && setCategoryValue(params.category)
    params?.price && setPriceValue(params.price)
    params?.keyword && setKeyword(params.keyword)

    getProducts(params?.availability, params?.category, params?.price, params?.keyword)
  }, [availabilityValue, keyword, priceValue, categoryValue.toString()])

  const handleCategoryValue = useCallback((arr) => {
    setCategoryValue(arr)
  }, [])

  const handleCategoryTitle = useCallback((arr) => {
    setCategoryTitle(arr)
  }, [])

  return (
    <>
      {productsError || filtersError ? (
        <div>Something went wrong, please try to reload page one more time.</div>
      ) : (
        <div className="container">
          <div className="searchFiltersWrapper">
            <div className="filterWrapper">
              <CollectionFilterCheckBox
                activeFilters={activeFilters}
                availabilityValue={null}
                categoryValue={categoryValue}
                collectionFilters={filters ? filters[0] : null}
                isBrigades={isBrigades}
                isLoading={isLoading()}
                setFilterTitle={handleCategoryTitle}
                setFilterValue={handleCategoryValue}
                setIsParamsRemoved={setIsCategoryParamsRemoved}
              />
              <CollectionFilterCheckBox
                activeFilters={activeFilters}
                availabilityValue={availabilityValue}
                categoryValue={categoryValue}
                collectionFilters={filters ? filters[1] : null}
                isLoading={isLoading()}
                setFilterTitle={setAvailabilityTitle}
                setFilterValue={setAvailabilityValue}
                setIsParamsRemoved={setIsAvailabilityParamsRemoved}
              />
              {isBrigades ?
                null
                :
                <CollectionFilter
                  collectionFilters={filters ? filters[2] : null}
                  filterValue={priceValue}
                  isLoading={isLoading()}
                  setFilterTitle={setPriceTitle}
                  setFilterValue={setPriceValue}
                  setIsParamsRemoved={setIsPriceParamsRemoved}
                />
              }
            </div>
            <Search
              isLoading={isLoading()}
              keyword={keyword}
              setKeyword={setKeyword}
              t={t}
            />
          </div>
          <ActiveFilters
            activeFilters={activeFilters}
            checkBoxFilters={filters ? filters[0] : null}
            isLoading={isLoading()}
            setAvailabilityTitle={setAvailabilityTitle}
            setAvailabilityValue={setAvailabilityValue}
            setCategoryTitle={setCategoryTitle}
            setCategoryValue={setCategoryValue}
            setIsAvailabilityParamsRemoved={setIsAvailabilityParamsRemoved}
            setIsCategoryParamsRemoved={setIsCategoryParamsRemoved}
            setIsKeywordParamsRemoved={setIsKeywordParamsRemoved}
            setIsPriceParamsRemoved={setIsPriceParamsRemoved}
            setKeyword={setKeyword}
            setPriceTitle={setPriceTitle}
            setPriceValue={setPriceValue}
          />
          <div>
            {products?.records && isLoading() ? (
              <div className="loaderWrapper" style={{ position: 'relative', width: '100%', zIndex: '3' }}>
                <Loader isLarge />
              </div>
            ) : null}
            <div className="collectionCard_list" style={{ position: 'relative' }}>
              {products?.records ? (
                products?.records?.length > 0 ? (
                  products?.records.map((item) => {
                    return (
                      <CollectionCard
                        image={item.image_url}
                        image_alt={item.image_alt}
                        isLoading={isLoading()}
                        key={Math.random()}
                        name={item.name}
                        price={item.display_price}
                        recyclingProgramTag={item.recycling_program_tag}
                        url={item.url}
                      />
                    )
                  })
                ) : (
                  <h3 dangerouslySetInnerHTML={{__html: t['tcred.collections.nothing_found_html']}} style={{ margin: '2rem 0rem' }}></h3>
                )
              ) : (
                <div className="loaderWrapper" style={{ width: '75px', zIndex: '3' }}>
                  <Loader isLarge />
                </div>
              )}
            </div>
          </div>
          {products?.pagination.total_count ? (
            <LoadMore
              displayedItemsNumber={products?.records?.length}
              isLoading={isLoading()}
              loadMoreProducts={loadMoreProducts}
              pagination={products?.pagination}
              t={t}
            />
          ) : null}
        </div>
      )}
    </>
  )
}

export default ListingPage

ListingPage.propTypes = {
  brigade_ids: PropTypes.array,
  isBrigades: PropTypes.bool,
  t: PropTypes.object,
}
